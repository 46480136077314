<template>
  <div
    :class="[
      'section',
      'section-color-' + color,
      darkModeClass,
      noMargin && 'no-margin',
    ]"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: "sand",
        validator: (value) => {
          return [
            "sand",
            "navy",
            "blue",
            "onyx",
            "pastel_pink",
            "pastel_green",
          ].includes(value)
        },
      },
      noMargin: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      darkModeClass() {
        return this.color === "navy" || this.color === "onyx" ? "cs-dark" : null
      },
    },
  }
</script>

<style lang="scss" scoped>
  .section {
    padding: $gutter 0;
    margin-bottom: $gutter-double !important;
    & + & {
      margin-top: $gutter-double-negative !important;
    }

    &.no-margin {
      margin-bottom: 0;
    }

    &-color-sand {
      background: $sand-tint;
      --shade: white;
      --border: #{$sand-600};
    }
    &-color-navy {
      color: white;
      background: $navy;
      --shade: #{$navy-400};
      --border: #{$navy-300};
      --link: #{$blue};
      --heading: white;
    }
    &-color-blue {
      background: $blue-tint;
      --shade: white;
    }
    &-color-onyx {
      background: $onyx;
      --shade: #{$onyx-400};
      --border: #{lighten($onyx-400, 5%)};
      --link: #{$blue};
      --heading: white;
    }
    &-color-pastel_pink {
      background: $red-tint;
      --shade: white;
    }
    &-color-pastel_green {
      background: $green-tint;
      --shade: white;
    }
  }
</style>
