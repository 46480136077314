<template>
  <SectionWithBackground :color="color" :no-margin="!margin">
    <component
      :is="element.name"
      v-for="element in nestedElements"
      :key="element.id"
      :element="element"
      :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
    />
  </SectionWithBackground>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  const NARROW_ELEMENTS = ["text_block", "fragrance_teaser", "headline", "faq"]

  export default {
    components: {
      faq: defineAsyncComponent(() => import("~/alchemy/elements/faq")),
      articles: defineAsyncComponent(
        () => import("~/alchemy/elements/articles"),
      ),
      cards: defineAsyncComponent(() => import("~/alchemy/elements/cards")),
      headline: defineAsyncComponent(() => import("./headline")),
      image_full_width: defineAsyncComponent(
        () => import("~/alchemy/elements/image_full_width"),
      ),
      gallery: defineAsyncComponent(() => import("~/alchemy/elements/gallery")),
      wistia_embed: defineAsyncComponent(
        () => import("~/alchemy/elements/wistia_embed"),
      ),
      product_slider: defineAsyncComponent(
        () => import("~/alchemy/elements/product_slider"),
      ),
      fragrance_teaser: defineAsyncComponent(
        () => import("~/alchemy/elements/fragrance_teaser"),
      ),
      grid_columns: defineAsyncComponent(
        () => import("~/alchemy/elements/grid_columns"),
      ),
      text_block: defineAsyncComponent(() => import("./text_block")),
      panel_row: defineAsyncComponent(
        () => import("~/alchemy/elements/panel_row"),
      ),
      top_selling_fragrance_oils: defineAsyncComponent(
        () => import("~/alchemy/elements/top_selling_fragrance_oils"),
      ),
    },
    mixins: [AlchemyElement],
    data() {
      return {
        NARROW_ELEMENTS,
      }
    },
    computed: {
      color() {
        return this.getValue("color")
      },
      margin() {
        return this.getValue("margin")
      },
      nestedElements() {
        return this.element.nestedElements
      },
    },
  }
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
